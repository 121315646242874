import './App.css';
import React, { useEffect, useState } from 'react';
import { getDatabase, off, ref, onValue } from "firebase/database";

// Function to parse the images.txt file data
const parseImageData = (rawData) => {
  return rawData.split('\n').reduce((acc, line) => {
    const [index, w1072, w633, w830] = line.trim().split('\t');
    if (index && !isNaN(parseInt(index))) {
      acc.push({ index: parseInt(index), w1072, w633, w830 });
    }
    return acc;
  }, []);
};

// Hash function to generate consistent numbers (for test mode)
const hashCode = (str) => {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
};

// Generate numbers for test mode
const generateNumbers = (sn) => {
  const hash = hashCode(sn);
  return [
    (hash % 130) + 1,
    ((hash >> 8) % 130) + 1,
    ((hash >> 16) % 130) + 1
  ];
};

function App() {
  const [data, setData] = useState(null);
  const [sn, setSn] = useState(null);
  const [dateData, setDateData] = useState(null);
  const [imageData, setImageData] = useState([]);
  const [isTestMode, setIsTestMode] = useState(false);
  const [isTest2Mode, setIsTest2Mode] = useState(false);
  const [error, setError] = useState(null);
  const [test2Content, setTest2Content] = useState(null);

  useEffect(() => {
    const url = window.location.href;
    console.log("url", url);

    const test2Mode = url.includes('&test2');
    const testMode = !test2Mode && url.includes('&test');
    setIsTestMode(testMode);
    setIsTest2Mode(test2Mode);
    console.log("isTestMode:", testMode);
    console.log("isTest2Mode:", test2Mode);

    const paramStartIndex = url.indexOf('uid=');
    if (paramStartIndex === -1) {
      console.log("No 'uid=' parameter found in the URL.");
      setError("No 'uid=' parameter found in the URL.");
      return;
    }

    const param = url.slice(paramStartIndex + 4).split(/[?&]/)[0];
    console.log("param :", param);

    if (param.length >= 14) {
      const hexString = param.substring(0, 14);
      const decimalValue = parseInt(hexString, 16);
      console.log("Decoded UID:", decimalValue);

      const snValue = decimalValue.toString();
      setSn(snValue);
      console.log("Setting sn:", snValue);
      fetchData(snValue);
    } else {
      console.log("Parameter is shorter than expected.");
      setError("Invalid parameter");
    }
  }, []);

  useEffect(() => {
    if (isTest2Mode && sn && data) {
      renderTest2Mode().then(content => setTest2Content(content));
    }
  }, [isTest2Mode, sn, data]);

  const fetchData = (snValue) => {
    const db = getDatabase();
    const deviceRef = ref(db, `forms/${snValue}`);
    console.log("Firebase reference:", `forms/${snValue}`);

    const handleValueChange = (snapshot) => {
      const value = snapshot.val();
      console.log("Firebase data received:", value);
      if (value) {
        setData(value);
        console.log("Data set:", value);
        fetchDateData(value.batchNo);
      } else {
        console.log("No data found for sn:", snValue);
        setError(`No data found for device: ${snValue}`);
      }
    };

    onValue(deviceRef, handleValueChange, error => {
      console.error("Firebase read failed:", error);
      setError("Error fetching data: " + error.message);
    });

    return () => {
      off(deviceRef, handleValueChange);
    };
  };

  const fetchDateData = (batchNo) => {
    console.log('Fetching date data for batchNo:', batchNo);
    if (!batchNo) {
      console.log('No batch number available, using default');
      batchNo = "0001";  // Use a default batch number if not available
    }
    const db = getDatabase();
    const dateDataRef = ref(db, `nfc_slot_prod/${batchNo}`);
    const handleValueChange = (snapshot) => {
      const value = snapshot.val();
      console.log('Date data fetched:', value);
      if (value) {
        setDateData(value);
      } else {
        console.log('No date data found for batchNo:', batchNo);
        setDateData({
          cs: "Not available",
          lsp: "Not available",
          cttm: "Not available",
          pd: "Not available",
          fpqt: "Not available",
          rfgd: "Not available"
        });
      }
    };

    onValue(dateDataRef, handleValueChange, error => {
      console.error("Firebase read failed:", error);
      setError("Error fetching date data: " + error.message);
    });

    return () => {
      off(dateDataRef, handleValueChange);
    };
  };

  useEffect(() => {
    // Always fetch image data, not just for test modes
    fetch('/images.txt')
      .then(response => response.text())
      .then(text => {
        const parsedData = parseImageData(text);
        setImageData(parsedData);
      })
      .catch(err => {
        console.error('Error loading image data:', err);
        setError('Error loading image data.');
      });
  }, []);

  const getImageSources = () => {
    if (!data) return [];

    const getBackupImageUrl = (wavelength) => {
      const generatedNumbers = generateNumbers(sn);
      const index = wavelength === 'w633' ? 0 : wavelength === 'w830' ? 1 : 2;
      const imageEntry = imageData.find(entry => entry.index === generatedNumbers[index]);
      return imageEntry ? imageEntry[wavelength] : null;
    };

    return [
      { name: 'Red 633nm', src: data.w633, backupSrc: getBackupImageUrl('w633') },
      { name: 'Near-Infrared 830nm', src: data.w830, backupSrc: getBackupImageUrl('w830') },
      { name: 'Deep Near-Infrared 1072nm', src: data.w1072, backupSrc: getBackupImageUrl('w1072') }
    ];
  };

  const getTestImageSources = () => {
    if (!isTestMode || !sn) return [];

    const getImageUrl = (wavelength, index) => {
      const imageEntry = imageData.find(entry => entry.index === index);
      return imageEntry ? imageEntry[wavelength] : null;
    };

    const generatedNumbers = generateNumbers(sn);
    return [
      { name: 'Red 633nm', src: getImageUrl('w633', generatedNumbers[0]), generatedNumber: generatedNumbers[0] },
      { name: 'Near-Infrared 830nm', src: getImageUrl('w830', generatedNumbers[1]), generatedNumber: generatedNumbers[1] },
      { name: 'Deep Near-Infrared 1072nm', src: getImageUrl('w1072', generatedNumbers[2]), generatedNumber: generatedNumbers[2] }
    ];
  };

  const renderTest2Mode = async () => {
    if (!sn || !data) {
      console.error("Serial number or data is not available");
      return <div>Error: Required data not available</div>;
    }

    const imageSources = getImageSources();
    const generatedNumbers = generateNumbers(sn);

    const getBackupImageUrl = (wavelength, index) => {
      const imageEntry = imageData.find(entry => entry.index === index);
      return imageEntry ? imageEntry[wavelength] : null;
    };

    const backupImages = [
      { name: 'Red 633nm', src: getBackupImageUrl('w633', generatedNumbers[0]), generatedNumber: generatedNumbers[0] },
      { name: 'Near-Infrared 830nm', src: getBackupImageUrl('w830', generatedNumbers[1]), generatedNumber: generatedNumbers[1] },
      { name: 'Deep Near-Infrared 1072nm', src: getBackupImageUrl('w1072', generatedNumbers[2]), generatedNumber: generatedNumbers[2] }
    ];

    const checkImage = (url) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
        img.src = url;
      });
    };

    const imageStatuses = await Promise.all(imageSources.map(async (img, index) => {
      const dbImageOk = await checkImage(img.src);
      const backupImageOk = await checkImage(backupImages[index].src);
      return dbImageOk ? 'DB' : (backupImageOk ? 'Backup' : 'None');
    }));

    return (
      <div className="mx-auto max-w-[550px] bg-white text-black font-lato">
        <h1 className="text-3xl font-bold mb-8">Test2 Mode</h1>

        <h2 className="text-2xl font-bold mb-4">Database Images</h2>
        {imageSources.map((img, index) => (
          <div key={index} className="mb-8">
            <p>URL for {img.name}: {img.src || 'blank'}</p>
            {img.src && <img className="w-full h-auto mt-2" src={img.src} alt={img.name} onError={(e) => { e.target.style.display = 'none'; }} />}
          </div>
        ))}

        <h2 className="text-2xl font-bold mb-4 mt-8">Backup Images</h2>
        {backupImages.map((img, index) => (
          <div key={index} className="mb-8">
            <p>{img.name}</p>
            <p>Generated Number: {img.generatedNumber}</p>
            {img.src ? (
              <img className="w-full h-auto mt-2" src={img.src} alt={img.name} onError={(e) => { e.target.style.display = 'none'; }} />
            ) : (
              <p>Backup image not available</p>
            )}
          </div>
        ))}

        <h2 className="text-2xl font-bold mb-4 mt-8">Image Status</h2>
        {imageStatuses.map((status, index) => (
          <p key={index}>{index + 1}. {status}</p>
        ))}
      </div>
    );
  };

  if (!isTestMode && !isTest2Mode && error) {
    return (
      <div className="mx-auto max-w-[550px] bg-white text-black font-lato">
        <header className="text-center py-8">
          <img className="w-[233px] h-[41px] mx-auto mb-6" alt="CurrentBody Skin Logo" src="https://www.currentbody.com/cdn/shop/t/1256/assets/currentbody-skin_1024x1024.png?v=170181410224247424011724169866" />
          <h1 className="text-4xl font-bold mb-4">Veritace® Testing & Traceability</h1>
          <img className="w-[331px] h-[246px] mx-auto mb-6" alt="LED Light Therapy Mask" src="currentbodymask.png" />
          <p className="text-3xl font-bold mb-2">LED Light Therapy Mask:<br></br>Series 2</p>
          <p className="text-sm mb-4">NFC card number: {sn}</p>
        </header>
        <div className="mx-auto flex items-center justify-center bg-white text-red-700 text-2xl font-bold font-lato p-4">
          <p>{error}</p>
        </div>
      </div>
    );
  }

  if (isTest2Mode) {
    return test2Content || (
      <div className="mx-auto flex items-center justify-center h-screen bg-white text-black-700 text-2xl font-bold font-lato">
        Loading Test2 Mode...
      </div>
    );
  }

  if (isTestMode || (data && dateData)) {
    const imageSources = getImageSources();

    return (
      <div className="mx-auto max-w-[550px] bg-white text-black font-lato">
        {/* Header */}
        <header className="text-center py-8">
          <a href="https://www.currentbody.com/collections/currentbody-skin">
            <img className="w-[233px] h-[41px] mx-auto mb-6" alt="CurrentBody Skin Logo" src="https://www.currentbody.com/cdn/shop/t/1256/assets/currentbody-skin_1024x1024.png?v=170181410224247424011724169866" />
          </a>
          <h1 className="text-4xl font-bold mb-4">Veritace® Testing & Traceability</h1>
          <img className="w-[331px] h-[246px] mx-auto mb-6" alt="LED Light Therapy Mask" src="currentbodymask.png" />
          <p className="text-3xl font-bold mb-2">LED Light Therapy Mask:<br></br>Series 2</p>
          <p className="text-sm mb-4">NFC card number: {sn}</p>
        </header>

        {/* Product Traceability */}
        <section className="px-6 mb-12 bg-gray-50 py-8">
          <h2 className="text-3xl font-bold mb-8">PRODUCT TRACEABILITY</h2>
          <div className="relative pl-12 border-l-2 border-black">
            {[
              { label: 'Component sourcing', date: dateData ? dateData.cs : "Loading..." },
              { label: 'LED selection process', date: dateData ? dateData.lsp : "Loading..." },
              { label: 'Component transfer to manufacturing', date: dateData ? dateData.cttm : "Loading..." },
              { label: 'Product build', date: dateData ? dateData.pd : "Loading..." },
              { label: 'Final product quality testing', date: dateData ? dateData.fpqt : "Loading..." },
              { label: 'Ready for global distribution', date: dateData ? dateData.rfgd : "Loading..." },
            ].map((item, index) => (
              <div key={index} className="mb-8 relative">
                <div className="absolute -left-[21px] top-1 w-6 h-6 bg-black rounded-full"></div>
                <div className="ml-4">
                  <p className="text-lg font-bold">{item.label}</p>
                  <p className="text-base">{item.date}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Spectroradiometer Precision Testing */}
        {!isTestMode && (
          <section className="px-6 mb-12">
            <h2 className="text-3xl font-bold mb-8">SPECTRORADIOMETER PRECISION TESTING</h2>
            {imageSources.length > 0 ? (
              imageSources.map((wavelength, index) => (
                <div key={index} className="mb-8">
                  <h3 className="text-2xl mb-2">{wavelength.name}</h3>
                  <p className="text-green-600 text-xl font-bold mb-2">PASSED √</p>
                  <img
                    className="w-full h-auto"
                    src={wavelength.src}
                    alt={`${wavelength.name} graph`}
                    onError={(e) => {
                      if (wavelength.backupSrc) {
                        e.target.src = wavelength.backupSrc;
                      } else {
                        e.target.style.display = 'none';
                      }
                    }}
                  />
                </div>
              ))
            ) : (
              <p>No test data available.</p>
            )}
          </section>
        )}

        {/* Test Mode Images */}
        {isTestMode && (
          <section className="px-6 mb-12">
            <h2 className="text-3xl font-bold mb-8">TEST MODE: Generated Images</h2>
            {imageSources.length > 0 ? (
              imageSources.map((wavelength, index) => (
                <div key={index} className="mb-8">
                  <h3 className="text-2xl mb-2">{wavelength.name}</h3>
                  <p>Generated Number: {generateNumbers(sn)[index]}</p>
                  <img
                    className="w-full h-auto"
                    src={wavelength.src}
                    alt={`${wavelength.name} test graph`}
                    onError={(e) => {
                      if (wavelength.backupSrc) {
                        e.target.src = wavelength.backupSrc;
                      } else {
                        e.target.style.display = 'none';
                      }
                    }}
                  />
                </div>
              ))
            ) : (
              <p>No test images available.</p>
            )}
          </section>
        )}

        {/* Testing Results */}
        <section className="px-6 mb-12 bg-gray-50 py-8">
          <h2 className="text-3xl font-bold mb-8">TESTING RESULTS</h2>
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-black text-white">
                <th className="p-3 text-left text-lg">Test</th>
                <th className="p-3 text-left text-lg">Status</th>
                <th className="p-3 text-left text-lg">Equipment</th>
              </tr>
            </thead>
            <tbody>
              {[
                { test: 'LED placement check', equipment: 'Imager' },
                { test: 'Device power output', equipment: 'Multimeter' },
                { test: 'Battery capacity', equipment: 'Battery capacity division system' },
                { test: 'Temperature', equipment: 'Humidity temperature meter' },
                { test: 'Standard and Electrical Safety', equipment: 'Third party laboratory' },
              ].map((item, index) => (
                <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                  <td className="p-3 text-base">{item.test}</td>
                  <td className="p-3 text-base font-bold whitespace-nowrap">
                    PASSED <span className="text-green-600 text-xl">√</span>
                  </td>
                  <td className="p-3 text-base">{item.equipment}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        {/* Product Information */}
        <section className="px-6 mb-12">
          <h2 className="text-3xl font-bold mb-8">CurrentBody Skin LED Light Therapy Mask: Series 2</h2>
          <div className="grid grid-cols-2 gap-4">
            <div className="font-bold">Production date</div><div>{dateData ? dateData.pd : "Loading..."}</div>
            <div className="font-bold">Battery capacity</div><div>2600mAh</div>
            <div className="font-bold">Dimensions</div><div>190 × 98 × 207 mm</div>
            <div className="font-bold">Treatment time</div><div>10 minutes (Auto-timer)</div>
            <div className="font-bold">Treatment protocol</div><div>3-5 times weekly</div>
            <div className="font-bold">Battery charging duration</div><div>4.5 hours</div>
            <div className="font-bold">Product operational time</div><div>10 uses per charge</div>
          </div>
        </section>

        {/* Footer */}
        <footer className="bg-black text-white py-8 text-center">
        </footer>
      </div>
    );
  }

  return (
    <div className="mx-auto flex items-center justify-center h-screen bg-white text-black-700 text-2xl font-bold font-lato">
      Loading...
    </div>
  );
}

export default App;
